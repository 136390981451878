export const CHAIN_ID = {
  BSC: {
    TESTNET: 97,
    MAINNET: 56,
  },
  ETH: {
    TESTNET: 3,
    MAINNET: 1,
  },
  KCC :{
    TESTNET: 322,
    MAINNET: 321,
  }
};
