import React, { useMemo, useState } from 'react';

import AssetToken from '../AssetToken'

import { Search } from "../../component/DataTable";

const AssetSelectModal = ({ listTokens, onClickSelect, selected }) => {

    const [search, setSearch] = useState("");
    const listTokensData = useMemo(() => {
        console.log('------------', listTokens);
        let computedListTokens = listTokens;
        if (search) {
            // debugger
            // console.log("search==>",search);
            computedListTokens = computedListTokens.filter(
                pool =>
                    pool.contractAddress.toLowerCase().includes(search.toLowerCase()) ||
                    pool.symbol.toLowerCase().includes(search.toLowerCase())
            );
        }
        //Sorting listProjects
        return computedListTokens
    }, [listTokens, search]);

    return (
        <>


            <div className="modal fade in" id="selectAssetModal" tabIndex="-1" aria-labelledby="selectAssetModalLabel" >
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title text-dark" id="selectAssetModalLabel">Select Asset</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="px-3 py-2">
                                <div className="mb-4">
                                    <Search onSearch={value => {
                                        setSearch(value);

                                    }}
                                        placeholder="Search by token name..."
                                    />
                                    {/* <input type="text" className="form-control search-token" placeholder="Search by token name..." /> */}
                                </div>
                                <div className="font-12 opacity-07">Token name</div>
                            </div>
                            <div className="p-assets">
                                {listTokensData.length > 0 &&
                                    listTokensData.map((token, key) => {
                                        return (
                                            <AssetToken token={token} key={key}
                                                selected={selected}
                                                onClickSelect={onClickSelect}
                                            />
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AssetSelectModal