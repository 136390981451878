import React, { useEffect, useState } from 'react';



export default function ButtonStep({ divClass, btnText, iClass }) {


    return (
        <div className={`${divClass}`} >
            <button type="button" className="step-trigger">
                <span className="bs-stepper-circle"><i className={`${iClass}`} /></span>
                <span className="bs-stepper-label text-capitalize">
                    {btnText}
                </span>
            </button>
        </div>
    );
}
