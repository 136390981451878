


export const extensionName = {
  metamask: "METAMASK",
  binanceExtension: "BINANCE_EXTENSION",
  trustWallet: "TRUST_WALLET",
  tronLink: "TRON_LINK"
};

export const TOKEN_TYPE ={
  ERC20:'ERC20',
  TRC20:'TRC20'
}


export const DEFAULT_SELECT_TOKENS ={
  SYMBOL:'BSCPAD'
}


export const STATUS ={
  APPROVING:'APPROVING',
  APPROVED:'APPROVED',
  APPROVE_FAILS:'APPROVE_FAILS',

  SWAP_SUBMITTING:'SWAP_SUBMITTING',
  SWAP_SUCCESS: 'SWAP_SUCCESS',
  SWAP_FAILS: 'SWAP_FAILS',


  // SWAP_SUBMIT:"SWAP_SUBMIT",
  // SWAP_SUCCESS:"SWAP_SUCCESS",
  // SWAP_FAIL: 'SWAP_FAIL'
}




export const BRIDGE_NETWORKS= [
  {
    name:`Ethereum Network`,
    symbol:`eth`,
    icon:`/images/networks/eth-icon.svg`
  },
  {
    name:`Binance Smart Chain Network`,
    symbol:`bsc`,
    icon:`/images/networks/bsc-icon.svg`
  },
  {
    name:`TRX Network`,
    symbol:`trx`,
    icon:`/images/networks/trx-icon.svg`
  },
]



export const LIMIT_VALUE ={
  MIN: 100,
  MAX: 9999999999
}
