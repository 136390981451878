import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import Loader from "react-loader-spinner";


export default function SwapStepContent({ status, step, amount, token, inputNetwork, outputNetwork, receiptHash }) {

    // const walletUtils = useSelector((state) => get(state, 'utils.walletUtils', null));
    // const [fee, setFee] = useState(0)


    return (
        <>

            <div className="bs-stepper-content">
                {
                    step === 1 && <div id="processStep1">
                        <div className="text-center">
                            <h5>Information</h5>
                            <p className="mt-4">You want to swap  {`${amount || 0} ${token.symbol}`} from {inputNetwork.name}&nbsp;
                                                to {outputNetwork.name}</p>
                            <div className="d-flex align-items-center justify-content-center mt-4">
                                <span className="text-purple me-2">You will receive</span>
                                <img className="mx-2" height="12" src={`${token.image}`} alt={`${token.name}`} />{' '}
                                {`${amount || 0} ${token.symbol}`}
                                <span className="badge ms-2 p-coin-tag">{outputNetwork.badge}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <span className="text-purple me-2">Swap fee:</span>
                                {`${token.fee} `} {inputNetwork.currency}
                            </div>

                        </div>
                    </div>
                }
                {
                    step === 2 && <div id="processStep2">
                        <div className="text-center">
                            <h5>Pre-authorization</h5>
                            <div className="font-14 text-purple">1<sup>st</sup> of 2 transactions required.</div>
                            <p className="mt-4">First transaction is the Pre-authorization step, where you allow the contract to swap your tokens up to the defined amount.</p>
                            <p className="font-14 text-purple mt-5"><i className="mdi mdi-alert me-1"></i>You will be asked to confirm that you allow the smart contract to swap <b>{amount}</b> {token.symbol} from your wallet.</p>
                        </div>
                    </div>
                }
                {
                    step === 3 && <div id="processStep3">
                        <div className="text-center">
                            <h5>Confirm</h5>
                            <p className="mt-4">Second transaction is the <b>Swap</b> step, where the provided amount of {token.symbol} tokens will be swapped via the contract.</p>
                            <p className="font-14 text-purple mt-5"><i className="mdi mdi-alert me-1"></i>This is the last transaction you need to make to finalize the swap.</p>
                        </div>
                    </div>
                }
                {
                    step === 4 && <div id="processStep4">
                        <div className="text-center">
                            <h5>Swap Submitted</h5>
                            {/* <p className="mt-4">Submit swap token {token.symbol} <br />From {inputNetwork.name}&nbsp;to&nbsp;{outputNetwork.name} successfully!</p> */}

                            <p>  
                                {
                                    status === "pending"&&
                                    <>
                                        <p className="mt-4"><i className="mdi mdi-alert me-1"></i>Please wait below for the completed swap transaction receipt from the {outputNetwork.name}.</p>
                                        <p className="font-13 text-warning">The swap process can take several minutes. If you do not receive a transaction receipt please contact us for further support.</p>
                                        <div className="lds-ripple"><div></div><div></div></div>
                                        {/* <Loader type="Circles" color="#dc3545" height={40} width={40} /> */}
                                    </>
                                }
                                {
                                    status === "completed"&&
                                   
                                     <p><i className="mdi mdi-check text-success me-2"></i> <span>{token.symbol} token swapped successfully!</span></p>
                                  
                                }
                                {
                                    status === "fail"&&
                                    <>
                                     <p><i className="mdi mdi-alert text-warning me-2"></i> <span>{token.symbol} token swapped fails!</span></p>
                                    </>
                                }  

                                {
                                    (inputNetwork.id === 'TRON' && status !== "pending") &&
                                    <>
                                      
                                        <a className="p-address text-warning" href={`${receiptHash}`} target="_blank" >{receiptHash.substring(receiptHash.length - 64)}</a>
                                    </>

                                }
                                {(inputNetwork.id === 'BSC' || inputNetwork.id === 'ETH' || inputNetwork.id === 'KCC') && status !== "pending" &&
                                    <>
                                        <a className="p-address text-warning" href={`${receiptHash}`} target="_blank">{receiptHash.substring(receiptHash.length - 64)}</a>
                                    </>

                                }

                            </p>
                        </div>
                    </div>
                }
            </div>

        </>
    );
}