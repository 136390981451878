
import React  from 'react';
import ButtonStep from './ButtonStepComponent';



export default function SwapLineStepTab({ step, currentStep, status }) {

    // console.log("status==>", status);

    return (
        <>

            <div className="bs-stepper-header" role="tablist">
                {/* your steps here */}

                <ButtonStep divClass={`step ${step === 1 ? "current" : ""} ${currentStep >= 1 ? "completed" : ""}`}
                    iClass='mdi mdi-format-list-checkbox'
                    btnText={currentStep === 0 ? "Confirmation" : "Confirmed"}
                />

                <div className="line" />

                <ButtonStep divClass={`step ${step === 2 ? "current" : ""} ${currentStep >= 2 ? "completed" : ""}`}
                    iClass='mdi mdi-currency-usd'
                    btnText={currentStep <= 1 ? "Authorization" : "Approved"}
                />
                <div className="line" />

                <ButtonStep divClass={`step ${step === 3 ? "current" : ""} ${currentStep >= 3 ? "completed" : ""}`}
                    iClass='mdi mdi-account-check-outline'
                    btnText={currentStep <= 2 ? "Depositing" : "Deposited"}
                />

                <div className="line" />
                <ButtonStep divClass={`step ${step === 4 ? "current" : ""} ${currentStep >= 4 ? status : ""}`}
                    iClass='mdi mdi-check'
                    btnText={step === 4 ? status : "Completion"}
                />
            </div>

        </>
    );
}
