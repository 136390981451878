import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, } from "react-router-dom";
import { isMobile } from "web3modal";
import { ACTION_CONST, ROUTES, } from "../../../constants";
import { helpers } from "../../../utils/helpers";

const Header = (props) => {

  const dispatch = useDispatch();
  // const [isBrowserMobile, setIsBrowserMobile] = useState(false)
  const isConnectWallet = useSelector((state) => get(state, "utils.isConnectWallet", false));
  const showModalHelp = useSelector((state) => get(state, "utils.showModalHelp", false));
  const walletAddress = useSelector((state) => get(state, "utils.walletAddress", false));
  const walletUtils = useSelector((state) => get(state, "utils.walletUtils", null));


  // const [init, setInit] = useState(true);

  const [amountBalance, setAmountBalance] = useState(0)



  useEffect(() => {

    if (walletUtils) {


      walletUtils.getBalanceAccount().then(balance => {
        setAmountBalance(balance)

      }).catch(err => console.log(err));

      // setInit(false)
      const job = setInterval(() => {

        walletUtils.getBalanceAccount().then(balance => {
          setAmountBalance(balance)

        }).catch(err => console.log(err));


      }, 10 * 1000);
      // set reducer getbalance
      dispatch({ type: ACTION_CONST.SET_JOB_GET_BALANCE, data: job })



    }
  }, [walletUtils])

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch({
      type: ACTION_CONST.LOG_OUT_WALLET_SUCCESS
    })

    window.location.reload();
  }
  return (
    <>
      <nav id="PPNavbar" className="navbar navbar-expand-md navbar-dark bg-dark">
        <div className="container">
          <a className="navbar-brand d-flex align-items-center" href="https://ethpad.network" target="_blank">
            <img src="/images/logo_w.png" height="45" alt="ETHPad" className="me-2" />
          </a>
          {isMobile() &&
            <div className="d-flex align-items-center" style={{ flexWrap: 'nowrap' }}>
              <div className="dropdown  d-block d-md-none d-lg-none d-xl-none">
              </div>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"

                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          }

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {/* <li className="nav-item">
                <Link className="nav-link" to={ROUTES.HOMEPAGE}>
                  Bridge
                    </Link>
              </li> */}
            </ul>
            <ul className={isConnectWallet ? 'navbar-nav ms-auto mb-2 mb-md-0 connected' : 'navbar-nav ms-auto mb-2 mb-md-0'}>

              {
                !isConnectWallet ?
                  <li className="nav-item me-2 li-connect">
                    <a className="btn btn-primary" href="#" data-bs-toggle="modal" data-bs-target={showModalHelp ? "#helpModal" : "#connectWalletModal"}>
                      <i className="mdi mdi-wallet-plus-outline me-1"></i>
                      <span>Connect Wallet</span>
                    </a>
                  </li>
                  :
                  <>
                    <li className="nav-item me-2 mb-2 mb-md-0">
                      <span className="d-flex align-items-center justify-content-center text-nowrap px-3" style={{
                        cursor: 'text',
                        border: '1px #6574c7 solid',
                        borderRadius: '20px',
                        color: '#6574c7',
                        height: '36px'
                      }}>
                        <b>{`${amountBalance}`}</b>
                      </span>
                      <a className="btn btn-primary d-inline-block d-md-none ms-2" href="#" onClick={(e) => handleLogout(e)}>
                        <i className="mdi mdi-logout-variant me-1"></i>
                        <span>Disconnect</span>
                      </a>
                    </li>
                    <li className="nav-item me-2 w-100 w-md-auto">
                      <a className="btn btn-primary w-100 w-md-auto" href="#" data-bs-toggle="modal" data-bs-target="#walletModal">
                        <i className="mdi mdi-wallet-plus-outline me-1"></i>
                        <span>{helpers.formatTransactionHash(walletAddress, 8, 8)}</span>
                      </a>
                    </li>
                    <li className="nav-item d-none d-md-block">
                      <a className="btn btn-primary" href="#" onClick={(e) => handleLogout(e)}>
                        <i className="mdi mdi-logout-variant me-1"></i>
                        <span>Disconnect</span>
                      </a>
                    </li>
                  </>
              }
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
