import { takeLatest, put, call } from "redux-saga/effects";

import { ACTION_CONST } from "../../constants";
import { getTokens } from "../services/tokens";

function* handleGetTokens() {
  try {
    const results = yield call(getTokens);
    // console.log("----handleGetUserInfo----", resProjects);
    if (results.status === 200) {
        // console.log(resProjects)
        yield put({
          type: ACTION_CONST.GET_TOKENS_SUCCESS,
          data: results.data
        }
        );
      }
  } catch (error) {
    console.log(error);
    return null;
  }

}

export function* watchSubmitGetTokens() {
  yield takeLatest(
    ACTION_CONST.SUBMIT_GET_TOKENS,
    handleGetTokens
  );
}
