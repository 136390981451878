export const ACTION_CONST = {
    REQUEST_SUBMIT:'REQUEST_SUBMIT',
    REQUEST_DONE:'REQUEST_DONE',
    ALERT_SUCCESS: 'ALERT_SUCCESS',
    ALERT_FAILS: 'ALERT_FAILS',
    ALERT_WARNING: 'ALERT_WARNING',
    ALERT_CLEAR: 'ALERT_CLEAR',
    ALERT_LINK: 'ALERT_LINK',
    ALERT_LINK_CLEAR: 'ALERT_LINK_CLEAR',

    CONNECT_WALLET_SUCCESS:'CONNECT_WALLET_SUCCESS',
    LOG_OUT_WALLET_SUCCESS :'LOG_OUT_WALLET_SUCCESS',
    ENABLE_WALLET_SUCCESS: 'ENABLE_WALLET_SUCCESS',

    CURRENT_NET_WORK_EXTENSION:'CURRENT_NET_WORK_EXTENSION',

    SET_SHOW_MODAL_HELP:'SET_SHOW_MODAL_HELP',
    

    GET_TOKENS_SUCCESS:"GET_TOKENS_SUCCESS",
    SUBMIT_GET_TOKENS:'SUBMIT_GET_TOKENS',

    SUBMIT_SELECTED_TOKEN: 'SUBMIT_SELECTED_TOKEN',
    GET_SELECTED_TOKEN_SUCCESS: 'GET_SELECTED_TOKEN_SUCCESS',

    SET_CURRENT_CONTRACT_SELECTED: 'SET_CURRENT_CONTRACT_SELECTED',
    
    SET_LIST_CONTRACT_SELECTED: 'SET_LIST_CONTRACT_SELECTED',


    SET_JOB_PROJECT_SELECTED: 'SET_JOB_PROJECT_SELECTED',
    SET_JOB_GET_WALLET_INFO: 'SET_JOB_GET_WALLET_INFO',
    SET_JOB_COUNT_DOWN_OPEN: 'SET_JOB_COUNT_DOWN_OPEN',
    SET_JOB_COUNT_DOWN_CLOSE:'SET_JOB_COUNT_DOWN_CLOSE',
    SET_JOB_COUNT_DOWN_ROUND:'SET_JOB_COUNT_DOWN_ROUND',

    CURRENT_INPUT_NETWORK:'CURRENT_INPUT_NETWORK',


    CLEAR_INTERVAL_JOB: 'CLEAR_INTERVAL_JOB',
    GET_INFO_WALLET: 'GET_INFO_WALLET',

    GET_GLITCH_BALANCE:'GET_GLITCH_BALANCE',
    GET_ETH_SWAP_FEE:'GET_ETH_SWAP_FEE',
    GET_BSC_SWAP_FEE:'GET_BSC_SWAP_FEE',

    SET_JOB_GET_BALANCE:'SET_JOB_GET_BALANCE',
    SET_JOB_GET_ASSET_BALANCE:'SET_JOB_GET_ASSET_BALANCE',
    GET_ASSET_BALANCE_SUCCESS:'GET_ASSET_BALANCE_SUCCESS',

    GET_ALLOWANCE:'GET_ALLOWANCE'

}
