import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ACTION_CONST } from "../../constants";
import { get } from "lodash";
import useCopyToClipboard from './CopyToClibboard';


const WalletModal = (props) => {
    const dispatch = useDispatch();
    const [copied, setCopied] = useCopyToClipboard(1000);
    const walletAddress = useSelector((state) =>
        get(state, "utils.walletAddress", false)
    );
    const handleLogout = () => {
        dispatch({
            type: ACTION_CONST.LOG_OUT_WALLET_SUCCESS
        })

        window.location.reload();
    }

    return (
        <>
            <div className="modal fade " id="walletModal" tabIndex="-1" aria-labelledby="walletModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title text-dark" id="walletModalLabel">Your wallet</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3 text-center">
                                <h5 style={{ fontSize: '17px', wordBreak: 'break-word' }} className="text-light mb-2 w-100 text-center">
                                    <b>{walletAddress}</b>
                                </h5>
                            </div>
                            <div className="text-center mt-4 d-flex align-items-center justify-content-center mb-3">
                                <a className="btn btn-primary d-flex align-items-center text-decoration-none" href="#" onClick={(e) => { e.preventDefault(); setCopied(walletAddress) }}>
                                    {!copied ? <i className="mdi mdi-content-copy"></i> : <i className="mdi mdi-check"></i>}<span className="ms-2">Copy Address</span>
                                </a>
                                <a className="btn btn-primary d-flex align-items-center text-decoration-none ms-3" href="#" onClick={handleLogout} data-bs-dismiss="modal">
                                    <span>Disconnect</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WalletModal;


